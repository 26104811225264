import TYPES from '@/types';

import Vue from 'vue';

// Application
import CreateHabitsAvailableBudgetCalculationCommand
  from '@/modules/flagship/habits-available-budget-calculation/application/commands/create-habits-available-budget-calculation-command';
import CreateHabitsRecommendedBudgetCalculationCommand
  from '@/modules/flagship/habits-recommended-budget-calculation/application/commands/create-habits-recommended-budget-calculation-command';
import UpdateInvestorProfileOnRetirementBudgetCommand
  from '@/modules/flagship/investor-profile/retirement-budget/application/commands/update-investor-profile-on-retirement-budget-command';
import GetRetirementInvestorGoalQuery
  from '@/modules/flagship/retirement-investor-goal/application/queries/get-retirement-investor-goal-query';
import GetIncomeBehaviorInFollowingYearQuery
  from '@/modules/flagship/catalogs/income-behavior-in-following-year/application/queries/get-income-behavior-in-following-year-query';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';

// Domain
import {
  HabitsAvailableBudgetCalculationDto,
} from '@/modules/flagship/habits-available-budget-calculation/domain/dtos/habits-available-budget-calculation-dto';
import {
  HabitsRecommendedBudgetCalculatorDto,
} from '@/modules/flagship/habits-recommended-budget-calculation/domain/dtos/habits-recommended-budget-calculator-dto';
import {
  RetirementBudgetDto,
} from '@/modules/flagship/investor-profile/retirement-budget/domain/dtos/retirement-budget-dto';
import {
  IncomeBehaviorInFollowingYearEntity,
} from '@/modules/flagship/catalogs/income-behavior-in-following-year/domain/entities/income-behavior-in-following-year-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class FlagshipProfilingStepFinancialHabitsViewModel {
  @Inject(TYPES.CREATE_HABITS_AVAILABLE_BUDGET_CALCULATION_COMMAND)
  private readonly create_available_calculation!: CreateHabitsAvailableBudgetCalculationCommand;

  @Inject(TYPES.CREATE_HABITS_RECOMMENDED_BUDGET_CALCULATION_COMMAND)
  private readonly create_recommended_calculation!: CreateHabitsRecommendedBudgetCalculationCommand;

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_ON_RETIREMENT_BUDGET_COMMAND)
  private readonly update_retirement_budget_comm!: UpdateInvestorProfileOnRetirementBudgetCommand;

  @Inject(TYPES.GET_RETIREMENT_INVESTOR_GOAL_QUERY)
  private readonly get_retirement_investor_query!: GetRetirementInvestorGoalQuery;

  @Inject(TYPES.GET_INCOME_BEHAVIOR_IN_FOLLOWING_YEAR_QUERY)
  // eslint-disable-next-line max-len
  private readonly get_income_behavior_in_following_year_query!: GetIncomeBehaviorInFollowingYearQuery;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.flagship.flagship-profiling.flagship_profiling_step_financial_habits';

  private readonly view!: Vue;

  net_monthly_income = '';

  net_monthly_expenses = '';

  monthly_required_amount = ''

  is_negative = false;

  is_disable = false;

  is_valid_form = false;

  income_behavior_next_year_options: Array<IncomeBehaviorInFollowingYearEntity> = [];

  income_behavior_in_following_year_id = '';

  rules = {
    net_monthly_expenses: [
      (value: string) => (
        (parseFloat(value.replace(/[^0-9.]/g, '')) <= parseFloat(this.net_monthly_income.replace(/[^0-9.]/g, '')))
        || this.translate('negative_message')
      ),
    ],
  };

  public constructor(view: Vue) {
    this.view = view;
  }

  get can_continue() {
    return !this.is_disable && this.income_behavior_in_following_year_id
      && this.net_monthly_income !== '$0 MXN' && this.net_monthly_expenses !== '$0 MXN';
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  createRecommendedCalculation = async () => {
    try {
      const recommended_budget_dto: HabitsRecommendedBudgetCalculatorDto = {
        net_monthly_income: parseFloat(this.net_monthly_income.replaceAll(/[^\d.-]/g, '')).toFixed(2),
      };

      await this.create_recommended_calculation.execute(recommended_budget_dto)
        .then((response: any) => {
          this.monthly_required_amount = response.monthly_required_amount.toString();
        });
      return true;
    } catch {
      return false;
    } finally {
      this.view.$emit('loadingStep', false);
      this.is_disable = false;
    }
  }

  createAvailableCalculation = async () => {
    try {
      this.view.$emit('loadingStep', true);
      this.is_disable = true;

      const available_budget_dto: HabitsAvailableBudgetCalculationDto = {
        net_monthly_income: this.net_monthly_income.replaceAll(/[^\d.-]/g, ''),
        net_monthly_expenses: this.net_monthly_expenses.replaceAll(/[^\d.-]/g, ''),
      };

      await this.create_available_calculation.execute(available_budget_dto)
        .then((response: any) => {
          this.monthly_required_amount = response.monthly_required_amount.toString();
        });

      this.is_negative = false;
      this.is_disable = false;
      return true;
    } catch {
      this.is_negative = true;
      this.is_disable = true;
      await this.createRecommendedCalculation();
      return false;
    } finally {
      this.view.$emit('loadingStep', false);
    }
  }

  netMonthlyChange = async () => {
    if (this.net_monthly_expenses !== '$0 MXN'
      && this.net_monthly_income !== '$0 MXN') {
      await this.createAvailableCalculation();
    } else {
      this.monthly_required_amount = '$0 MXN';
    }
  }

  updateInformation = async () => {
    try {
      this.view.$emit('loadingStep', true);

      const retirement_investor = await this.get_retirement_investor_query.execute();

      const retirement_budget_dto: RetirementBudgetDto = {
        investor_goal_id: retirement_investor.investor_goal_id,
        net_monthly_income: Number(parseFloat(this.net_monthly_income.replaceAll(/[^\d.-]/g, '')).toFixed(2)),
        net_monthly_expenses: Number(parseFloat(this.net_monthly_expenses.replaceAll(/[^\d.-]/g, '')).toFixed(2)),
        monthly_required_amount: Number(parseFloat(this.monthly_required_amount.replaceAll(/[^\d.-]/g, '')).toFixed(2)),
        income_behavior_in_following_year_id: this.income_behavior_in_following_year_id,
      };

      await this.update_retirement_budget_comm.execute(retirement_budget_dto);

      this.view.$emit('nextStep');

      return true;
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.messageNotifier.showErrorNotification(
          this.translate('errors.update_profile'),
        );
      }

      return false;
    } finally {
      this.view.$emit('loadingStep', false);
    }
  }

  loadIncomeBehaviorNextYearOptions = async () => {
    try {
      this.income_behavior_next_year_options = await this
        .get_income_behavior_in_following_year_query.execute();
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.load_income_behavior_next_year_options'),
      );
    }
  }

  loadInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute();
      this.net_monthly_income = investor_profile.net_monthly_income.toString();
      this.net_monthly_expenses = investor_profile.net_monthly_expenses.toString();
      this.income_behavior_in_following_year_id = investor_profile
        .income_behavior_in_following_year_id;
      await this.netMonthlyChange();
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.load_investor_profile'),
      );
    }
  }

  initialize = async () => {
    this.view.$emit('loadingStep', true);
    await this.loadIncomeBehaviorNextYearOptions();
    await this.loadInvestorProfile();
    this.view.$emit('loadingStep', false);
  }
}
