




































































































































import { Component, Vue } from 'vue-property-decorator';
import FlagshipProfilingStepFinancialHabitsViewModel
  from '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-step-financial-habits-view-model';

@Component({
  name: 'FlagshipProfilingStepFinancialHabits',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class FlagshipProfilingStepFinancialHabits extends Vue {
  flagship_habits_view_model = Vue.observable(
    new FlagshipProfilingStepFinancialHabitsViewModel(this),
  );

  created() {
    this.flagship_habits_view_model.initialize();
  }
}
